html,
body,
#root {
  background-color: var(--color-theme-background);
  color: var(--color-theme-text);
  height: 100%;
}

#root .ax-progress-infinite {
  margin-top: 50vh;
}
